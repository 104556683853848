<template>
  <div class="myphoto">
    <van-nav-bar
      title="证件照"
      left-arrow
      fixed
      @click-left="goBack"
      @click-right="isDropdownMenu = !isDropdownMenu"
    >
      <template #right>
        <van-icon size="22" name="apps-o" />
      </template>
    </van-nav-bar>
    <div
      class="downMenuModal"
      v-if="isDropdownMenu"
      @click="isDropdownMenu = false"
    >
      <div v-if="isDropdownMenu" class="dropdownMenu">
        <div class="menuItem" @click="$router.push('/')">
          <span>首页</span>
        </div>
        <div class="menuItem" @click="$router.push('/xuanke')">
          <span>选课中心</span>
        </div>
        <div class="menuItem" @click="$router.push('/tiku')">
          <span>题库</span>
        </div>
        <div class="menuItem" @click="$router.push('/homeLiveList')">
          <span>直播</span>
        </div>
        <div class="menuItem" @click="$router.push('/learning')">
          <span>学习中心</span>
        </div>
        <div class="menuItem" @click="downApp">
          <span>下载APP</span>
        </div>
      </div>
    </div>
    <div class="content-box">
      <van-image-preview v-model="showActive" :images="images">
      </van-image-preview>
      <div class="list-box">
        <img :src="photoObj.identificationPhotoUrl" />
      </div>
      <div class="detail-box">
        <div class="shuoming-box">
          <div class="first-box">规格详情</div>
          <div class="detail-title-spec">
            <span class="detail-left">像素尺寸：</span>
            <span class="detail-right">{{ getSize(photoObj) }}</span>
            <span class="detail-left" style="margin-left: 10px"
              >文件大小：</span
            >
            <span class="detail-right">{{ photoObj.fileSize }}</span>
          </div>
          <div class="back-box" style="margin-top: 7px">
            <span class="detail-left">背景色：</span>
            <span
              class="bgcolor-box"
              :style="{ background: photoObj.bgColor }"
            ></span>
          </div>
        </div>
      </div>
      <div class="savePhoto" @click="handleClick()">
        点击预览，长按保存到相册
      </div>
    </div>
  </div>
</template>

<script>
import {} from "@/api/photo.js";
import * as imageConversion from "image-conversion";
import { setInitImg } from "@/utils/transferFile.js";
import { ImagePreview } from "vant";
export default {
  name: "myphoto",
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  data() {
    return {
      isDropdownMenu: false,
      data: [],
      showActive: false,
      images: [],
      photoObj: {
        identificationPhotoUrl: "",
      },
    };
  },
  mounted() {
    this.init();
  },

  methods: {
    downApp() {
      window.location.href = "https://www.saen.com/app/";
    },
    getSize(item) {
      return item.width + "*" + item.height + "px";
    },
    goBack() {
      this.$router.go(-1);
    },
    init() {
      const lists = localStorage.getItem("photoDetail");

      this.photoObj = JSON.parse(lists);
      console.log("this.photoObj", this.photoObj);
    },

    //把图片文件作为参数传递到方法中
    beforeAvatarUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        console.log("上传头像图片只能是 JPG 或 PNG 格式!");
        return false;
      }
      return new Promise((resolve) => {
        // 压缩到100KB,这里的100就是要压缩的大小,可自定义
        imageConversion.compressAccurately(file, 18).then((res) => {
          resolve(res);
        });
      });
    },

    translateBlobToBase64(blob, callback) {
      var reader = new FileReader();
      reader.onload = function (e) {
        callback(e.target.result);
      };
      reader.readAsDataURL(blob);
      //读取后，result属性中将包含一个data:URL格式的Base64字符串用来表示所读取的文件
    },

    handleClick() {
      localStorage.setItem("downloadPhoto",this.photoObj.identificationPhotoUrl);

      this.$router.push({
        path: "/downloadPhoto",
        query:{
          type:'list'
        }
      });
      // setInitImg(this.photoObj.identificationPhotoUrl,(file)=>{
      //   console.log("file",file);
      //   this.beforeAvatarUpload(file).then((res) => {
      //       // //最后把file转为base64放到页面上显示
      //       console.log("1111",res);
      //       this.translateBlobToBase64(res, function (e) {
      //           that.images = [e];
      //           that.showActive = true;
      //         //that.savePhoto(e);

      //       });
      //   });
      // })
      //   this.getImageFileFromUrl(this.photoObj.identificationPhotoUrl, "fileName")
      //     .then((response) => {
      //       // 返回的是文件对象，使用变量接收即可
      //        console.log("1111");

      //     })
      //     .catch((e) => {
      //       console.error(e);
      //     });
    },
    getImageFileFromUrl(url, imageName) {
      return new Promise((resolve, reject) => {
        var blob = null;
        var xhr = "";
        console.log(window.XMLHttpRequest);
        if (window.XMLHttpRequest) {
          xhr = new XMLHttpRequest();
        } else {
          xhr = ActiveXObject("Micsoft.XMLHTTP"); //兼容老版本Ie
        }
        xhr.open("GET", url);
        xhr.setRequestHeader("Accept", "image/png");
        xhr.responseType = "blob";
        // 加载时处理
        xhr.onload = () => {
          // 获取返回结果
          blob = xhr.response;
          let imgFile = new File([blob], imageName, { type: "image/png" });
          // 返回结果
          resolve(imgFile);
        };
        xhr.onerror = (e) => {
          reject(e);
        };
        // 发送
        xhr.send();
      });
    },
    base64ImgtoFile(dataurl, filename = "file") {
      //将base64格式分割：['data:image/png;base64','XXXX']
      const arr = dataurl.split(",");
      // .*？ 表示匹配任意字符到下一个符合条件的字符 刚好匹配到：
      // image/png
      const mime = arr[0].match(/:(.*?);/)[1]; //image/png
      //[image,png] 获取图片类型后缀
      const suffix = mime.split("/")[1]; //png
      const bstr = atob(arr[1]); //atob() 方法用于解码使用 base-64 编码的字符串
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime,
      });
    },
    getBase64URL(pic) {
      const blob = this.base64ImgtoFile(pic);
      const blobUrl = window.URL.createObjectURL(blob);
      return blobUrl;
    },
    savePhoto(e) {
      let Url = this.photoObj.identificationPhotoUrl; //图片路径，也可以传值进来
      console.log("url", Url);
      //Url = this.imgURL //图片路径，也可以传值进来
      var triggerEvent = "touchstart"; //指定下载方式
      var blob = new Blob([""], { type: "application/octet-stream" }); //二进制大型对象blob
      var url = URL.createObjectURL(blob); //创建一个字符串路径空位
      var a = document.createElement("a"); //创建一个 a 标签
      a.href = Url; //把路径赋到a标签的href上
      //正则表达式，这里是把图片文件名分离出来。拿到文件名赋到a.download,作为文件名来使用文本
      a.download = Url.replace(/(.*\/)*([^.]+.*)/gi, "$2").split("?")[0];
      /* var e = document.createEvent('MouseEvents');  //创建事件（MouseEvents鼠标事件）
     e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null); //初始化鼠标事件（initMouseEvent已弃用）*/
      //代替方法。创建鼠标事件并初始化（后面这些参数我也不清楚，参考文档吧https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/MouseEvent）
      var e = new MouseEvent(
        "click",
        (true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
      );
      //派遣后，它将不再执行任何操作。执行保存到本地
      a.dispatchEvent(e);
      //释放一个已经存在的路径（有创建createObjectURL就要释放revokeObjectURL）
      URL.revokeObjectURL(url);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.downMenuModal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;

  .dropdownMenu {
    position: fixed;
    right: 10px;
    top: 44px;
    background-color: #fff;
    z-index: 999;
    width: 100px;
    min-height: 200px;
    box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.5);

    .menuItem {
      height: 40px;
      line-height: 40px;
      font-size: 13px;
      padding-left: 20px;
    }
    .menuItem5 {
      color: #ef8f27;
    }
  }
}
.first-box {
  color: #ecb213;
  font-size: 15px;
  font-weight: bold;
}
.detail-title-spec {
  margin-top: 7px;
}
.detail-left {
  color: #ada1a1;
}
.detail-right {
  color: #5a5757;
  font-weight: 600;
}

.myphoto {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.content-box {
  width: 100%;
  height: calc(100vh - 46px);
  margin-top: 46px;
  background-color: #f9f7f7;
  .savePhoto {
    width: calc(100% - 50px);
    margin-left: 20px;
    background-color: #5d7dff;
    color: white;
    border-radius: 15px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin-top: 30px;
  }
  .detail-box {
    width: calc(100% - 50px);
    margin-left: 20px;
    background-color: white;
    border-radius: 4px;
    height: 95px;
    .shuoming-box {
      padding: 10px;
      .back-box {
        display: flex;
        flex-direction: row;
        .bgcolor-box {
          display: block;
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
      }
    }
  }
  .list-box {
    width: 100%;
    height: 270px;
    display: flex;
    justify-content: center;
    img {
      width: 50%;
      height: 220px;
      margin-top: 20px;
    }
  }
}
</style>
